import React from 'react'
import { graphql } from 'gatsby'
import { mapEdgesToNodes, getPageLang } from '../lib/helpers'
import Container from '../components/container'
import Layout from '../containers/layout'

import Post from '../components/blog/post'

export const query = graphql`
  query PostPageQuery {
    pricings: allSanityBlog(limit: 6) {
      edges {
        node {
          title
          pageTitle
          pageSubtitle
          categories
          posts {
            title
            subtitle
            urlTitle
            date
            categories
            centerImage {
              asset {
                _id
              }
            }
            _rawPageText
          }
          subscribeTitle
          subscribePlaceholder
          subscribeButton
          subscribeLink
        }
      }
    }
  }
`

const PostPage = ({ data, pageContext }) => {
  const blog = (data || {}).pricings
    ? mapEdgesToNodes(data.pricings)
    : []

  const lang = getPageLang(pageContext)

  return (
    <Layout pageContext={pageContext} title={pageContext.post && pageContext.post.title}>
      <Container>
        <Post
          blog={blog}
          lang={lang}
          post={pageContext.post}
          blogTitle={pageContext.blog}
        />
      </Container>
    </Layout>
  )
}

export default PostPage
