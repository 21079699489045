import React from 'react'
// import { cn } from '../../lib/helpers'
import BlockContent from '../block-content'
import { buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'

import * as styles from './post.module.css'

const Post = ({ blogTitle, post = {}, lang }) => {
  return (
    <div className={styles.post}>
      <h1>{post.title}</h1>
      <h2>{post.date}</h2>

      <p className={styles.nav}>
        <a href='/blog'>{blogTitle}</a>
        <span> » </span>
        {post.title}
      </p>

      <div className={styles.image}>
        {post.centerImage &&
          <img className='lazyload' data-src={imageUrlFor(buildImageObj(post.centerImage)).url()} />}
      </div>

      <BlockContent blocks={post._rawPageText || []} />
    </div>
  )
}

export default Post
